import React from "react";
import { PopupButton } from "react-calendly";
import { APPEND_CALENDLY_URL } from "constants";
import * as F from "./coachingPlansPro.styled";
// Images
import GreenTick from "assets/images/myCoaching/Icon-check.svg";
import CrossIcon from "assets/images/myCoaching/Icon-Cross.svg";
import GreenTickWhiteBg from "assets/images/Page2/Icon-check-green-white.svg";

const CoachingPlansPro = ({ coach = null, onLeadModal }) => {
  return (
    <F.CoachingPlansBox className="plansBox">
      <F.CoachingPlansBoxInner>
        <F.PlanBox className="nonActive pl-0-small">
          <F.PlanBoxHead>
            <F.TitleInfo className="ct-text-block uppercase text-base font-normal color-paragraph-alt mt-0 mb-0">
              Coaching PLUS
            </F.TitleInfo>
            <F.Title className="ct-text-block color-dark text-4xl mb-12 font-medium mt-5 mb-0">
              ab 0,00 €
            </F.Title>
            <F.SubTitle className="mb-0">100% Kostenrückerstattung durch die gesetzliche Krankenkasse.</F.SubTitle>
          </F.PlanBoxHead>
          <F.PlanLowerBox>
            <F.PlanBoxIcons>
              <li>
                <F.PlanBoxIconBox>
                  <img src={GreenTick} alt="Tick" />
                </F.PlanBoxIconBox>
                <F.PlanBoxInformation className="ct-text-block text-base color-paragraph">
                  Erstgespräch
                </F.PlanBoxInformation>
              </li>
              <li>
                <F.PlanBoxIconBox>
                  <img src={GreenTick} alt="Tick" />
                </F.PlanBoxIconBox>
                <F.PlanBoxInformation className="ct-text-block text-base color-paragraph">
                  Unterstützung beim Erstellen deines Ernährungsplanes
                </F.PlanBoxInformation>
              </li>
              <li>
                <F.PlanBoxIconBox>
                  <img src={GreenTick} alt="Tick" />
                </F.PlanBoxIconBox>
                <F.PlanBoxInformation className="ct-text-block text-base color-paragraph">
                  Erklärung aller Funktionen der Foodiary App
                </F.PlanBoxInformation>
              </li>
              <li>
                <F.PlanBoxIconBox>
                  <img src={CrossIcon} alt="Tick" />
                </F.PlanBoxIconBox>
                <F.PlanBoxInformation className="ct-text-block text-base color-paragraph">
                  Persönlicher Coach für 3 Monate
                </F.PlanBoxInformation>
              </li>
              <li>
                <F.PlanBoxIconBox>
                  <img src={CrossIcon} alt="Tick" />
                </F.PlanBoxIconBox>
                <F.PlanBoxInformation className="ct-text-block text-base color-paragraph">
                  Wöchentliche Fortschritts - Gespräche mit Tipps
                </F.PlanBoxInformation>
              </li>
              <li>
                <F.PlanBoxIconBox>
                  <img src={CrossIcon} alt="Tick" />
                </F.PlanBoxIconBox>
                <F.PlanBoxInformation className="ct-text-block text-base color-paragraph">
                  Foodiary PRO Funktionen
                </F.PlanBoxInformation>
              </li>
            </F.PlanBoxIcons>
          </F.PlanLowerBox>
          {/* <PopupButton
            className="ct-link-text foodiary-outline-button w-100"
            styles={{
            }}
            url={`${coach?.pp_first_meeting_calendly_url_profilpage}${APPEND_CALENDLY_URL}`}
            // url={`https://calendly.com/foodiary-head-coaches/coaching-basic-terminvereinbarung-webseite-round?hide_gdpr_banner=1`}
            rootElement={document.getElementById("root")}
            text="Jetzt Termin vereinbaren"
          /> */}

          <button
            className="ct-link-text foodiary-outline-button w-100"
            onClick={() => onLeadModal()}
          >
            Erstgespräch vereinbaren
          </button>
        </F.PlanBox>
        <F.PlanBox className="active">
          <F.PlanBoxHead>
            <F.TitleInfo className="ct-text-block uppercase text-base font-normal color-paragraph-alt mt-0 mb-0 active">
              Coaching Pro
            </F.TitleInfo>
            <F.Title className="ct-text-block color-dark text-4xl mb-12 font-medium mt-5 mb-0">
              ab 100,00 €
            </F.Title>
            <F.SubTitle className="mb-0">pro Monat</F.SubTitle>
          </F.PlanBoxHead>
          <F.PlanLowerBox>
            <F.PlanBoxIcons>
              <li>
                <F.PlanBoxIconBox>
                  <img src={GreenTickWhiteBg} alt="Tick" />
                </F.PlanBoxIconBox>
                <F.PlanBoxInformation className="ct-text-block text-base color-paragraph">
                  Erstgespräch
                </F.PlanBoxInformation>
              </li>
              <li>
                <F.PlanBoxIconBox>
                  <img src={GreenTickWhiteBg} alt="Tick" />
                </F.PlanBoxIconBox>
                <F.PlanBoxInformation className="ct-text-block text-base color-paragraph">
                  Unterstützung beim Erstellen deines Ernährungsplanes
                </F.PlanBoxInformation>
              </li>
              <li>
                <F.PlanBoxIconBox>
                  <img src={GreenTickWhiteBg} alt="Tick" />
                </F.PlanBoxIconBox>
                <F.PlanBoxInformation className="ct-text-block text-base color-paragraph">
                  Erklärung aller Funktionen der Foodiary App
                </F.PlanBoxInformation>
              </li>

              <li>
                <F.PlanBoxIconBox>
                  <img src={GreenTickWhiteBg} alt="Tick" />
                </F.PlanBoxIconBox>
                <F.PlanBoxInformation className="ct-text-block text-base color-paragraph">
                  Persönlicher Coach für 3 Monate
                </F.PlanBoxInformation>
              </li>
              <li>
                <F.PlanBoxIconBox>
                  <img src={GreenTickWhiteBg} alt="Tick" />
                </F.PlanBoxIconBox>
                <F.PlanBoxInformation className="ct-text-block text-base color-paragraph">
                  Wöchentliche Fortschritts - Gespräche mit Tipps
                </F.PlanBoxInformation>
              </li>
              <li>
                <F.PlanBoxIconBox>
                  <img src={GreenTickWhiteBg} alt="Tick" />
                </F.PlanBoxIconBox>
                <F.PlanBoxInformation className="ct-text-block text-base color-paragraph">
                  Foodiary PRO Funktionen
                </F.PlanBoxInformation>
              </li>
            </F.PlanBoxIcons>
          </F.PlanLowerBox>
          {/* <PopupButton
            className="ct-link-text foodiary-green-button w-100"
            url={`${coach?.pp_first_meeting_calendly_url_profilpage}${APPEND_CALENDLY_URL}`}
            // url={`https://calendly.com/foodiary-head-coaches/coaching-basic-terminvereinbarung-webseite-round?hide_gdpr_banner=1`}
            rootElement={document.getElementById("root")}
            text="Jetzt Termin vereinbaren"
          /> */}

          <button
            className="ct-link-text foodiary-green-button w-100"
            onClick={() => onLeadModal()}
          >
            Erstgestpräch vereinbaren
          </button>
        </F.PlanBox>
      </F.CoachingPlansBoxInner>
    </F.CoachingPlansBox>
  );
};

export default CoachingPlansPro;
